import { createSlice } from '@reduxjs/toolkit';

/* Set up initial state */
const initialAuthState = {
    token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null,
    userName: localStorage.getItem('userName') !== null ? localStorage.getItem('userName') : null,
    userRoles: localStorage.getItem('userRoles') !== null ? JSON.parse(localStorage.getItem('userRoles')) : null,
};

/* Set up slice */
const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login(state, action) {
            /* Populate user role array */
            const userRoles = action.payload.userRoles.map((item) => {
                return item.id;
            });

            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('userName', action.payload.userName);
            localStorage.setItem('userRoles', JSON.stringify(userRoles));
            
            state.token = action.payload.token;
            state.userName = action.payload.userName;
            state.userRoles = userRoles;
        },
        logout(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRoles');

            state.token = null;
            state.userName = null;
            state.userRoles = null;
        },
    }
});

export const authActions = authSlice.actions;
export default authSlice;