import { createSlice } from '@reduxjs/toolkit';

/* Set up initial state */
const initialDeliveryRunSheetRecordsState = {
    deliveryRunSheetRecords: []
};

/* Set up slice */
const deliveryRunSheetRecordSlice = createSlice({
    name: 'deliveryRunSheetRecords',
    initialState: initialDeliveryRunSheetRecordsState,
    reducers: {
        addDeliveryRunSheetRecord(state, action) {
            state.deliveryRunSheetRecords.push(action.payload);
        },
        updateDeliveryRunSheetRecord(state, action) {
            const updatedDeliveryRunSheetRecords = state.deliveryRunSheetRecords.map((item) => {
                if(item.id === action.payload.id) {
                    return action.payload;
                }
                else {
                    return item;
                }
            });
            state.deliveryRunSheetRecords = updatedDeliveryRunSheetRecords;
        },
        deleteDeliveryRunSheetRecord(state, action) {
            state.deliveryRunSheetRecords = state.deliveryRunSheetRecords.filter((item) => {
                return item.id !== action.payload.id
            });
        },
        populateDeliveryRunSheetRecords(state, action) {
            state.deliveryRunSheetRecords = action.payload;
        },
        resetDeliveryRunSheetRecords(state) {
            state.deliveryRunSheetRecords = [];
        }
    }
});

export const deliveryRunSheetRecordActions = deliveryRunSheetRecordSlice.actions;
export default deliveryRunSheetRecordSlice;