import { createSlice } from '@reduxjs/toolkit';

/* Set up initial state */
const initialManifestRecordsState = {
    manifestRecords:[]
};

/* Set up slice */
const manifestRecordSlice = createSlice({
    name: 'manifestRecords',
    initialState: initialManifestRecordsState,
    reducers: {
        addManifestRecord(state, action) {
            state.manifestRecords.unshift(action.payload);
        },
        updateManifestRecord(state, action) {
            const updatedManifestRecords = state.manifestRecords.map((item) => {
                if(item.id === action.payload.id) {
                    return action.payload;
                }
                else {
                    return item;
                }
            });
            state.manifestRecords = updatedManifestRecords;
        },
        deleteManifestRecord(state, action) {
            state.manifestRecords = state.manifestRecords.filter((item) => {
                return item.id !== action.payload.id
            });
        },
        populateManifestRecords(state, action) {
            state.manifestRecords = action.payload;
        },
        resetManifestRecords(state) {
            state.manifestRecords = [];
        }
    }
});

export const manifestRecordActions = manifestRecordSlice.actions;
export default manifestRecordSlice;