import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { findArrayIntersection } from '../constants/global';

/**
 * Private Route forces authentication before the route can be accessed.
 * @param {*} param0
 * @returns
*/
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    
    const auth = useSelector((state) => {
        return state.auth;
    });

    return (
        <Route
            {...rest}
            render={(props) => {
                if(auth.token === null) {
                    /* Not logged in so redirect to login page with the return url */
                    return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                }

                /* Check if the route is restricted by a role */
                if(roles) {
                    if(findArrayIntersection(auth.userRoles, roles).length === 0) {
                        return <Redirect to={{ pathname: '/dashboard' }} />;
                    }
                }

                /* Authorized user so return the component */
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
