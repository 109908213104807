export const GlobalValues = {
    //baseUrl: 'http://localhost:8000/api/',
    baseUrl: 'https://api.taclogistics.in/api/',
    
    //clientBaseUrl: 'http://localhost:3000/',
    clientBaseUrl: 'https://webapp.taclogistics.in/',
    
    /* Role constants */
    superAdminRoleId: 1,
    headquarterBranchUserRoleId: 2,
    branchUserRoleId: 3,
    deliveryRunSheetUserRoleId: 4,
    requisitionRequestUserRoleId: 5,

    /* Co-Loader service type constants */
    coLoadingServiceType: 'CO-LOADER',
    transportingServiceType: 'TRANSPORTER',

    /* Authentication urls */
    loginUrl: 'user/login/',

    /* State urls */
    listStatesUrl: 'state/list/',

    /* Hub urls */
    createHubUrl: 'hub/create/',
    manageHubUrl: 'hub/manage/',
    listHubsUrl: 'hub/list/',
    listFilterHubsUrl: 'hub/list-filter/',

    /* Branch urls */
    createBranchUrl: 'branch/create/',
    manageBranchUrl: 'branch/manage/',
    listBranchesUrl: 'branch/list/',
    listFilterBranchesUrl: 'branch/list-filter/',

    /* Location urls */
    createLocationUrl: 'location/create/',
    manageLocationUrl: 'location/manage/',
    listFilterLocationsUrl: 'location/list-filter/',
    listUniqueDeliveryLocationsUrl: 'location/delivery-location-list/',

    /* Client urls */
    createClientUrl: 'client/create/',
    manageClientUrl: 'client/manage/',
    listClientsUrl: 'client/list/',
    listFilterClientsUrl: 'client/list-filter/',

    /* Client branch urls */
    createClientBranchUrl: 'client_branch/create/',
    manageClientBranchUrl: 'client_branch/manage/',
    listClientBranchesUrl: 'client_branch/list/',
    listFilterClientBranchesUrl: 'client_branch/list-filter/',

    /* Client branch location urls */
    createClientBranchLocationUrl: 'client_branch/create-location/',
    manageClientBranchLocationUrl: 'client_branch/manage-location/',
    listFilterClientBranchLocationsUrl: 'client_branch/list-filter-location/',

    /* Co-loader urls */
    createCoLoaderUrl: 'co_loader/create/',
    manageCoLoaderUrl: 'co_loader/manage/',
    listCoLoadersUrl: 'co_loader/list/',
    listFilterCoLoadersUrl: 'co_loader/list-filter/',
    coLoaderHubsUrl: 'co_loader/list-hubs/',
    createCoLoaderHubsUrl: 'co_loader/create-hubs/',

    /* Remark urls */
    createRemarkUrl: 'remark/create/',
    manageRemarkUrl: 'remark/manage/',
    listRemarksUrl: 'remark/list/',
    listFilterRemarksUrl: 'remark/list-filter/',

    /* User urls */
    createAdminUrl: 'user/create-super-admin/',
    updateAdminUrl: 'user/update-super-admin/',
    createUserUrl: 'user/create-user/',
    updateUserUrl: 'user/update-user/',
    manageUserUrl: 'user/manage-user/',
    manageUserActivityUrl: 'user/manage-user-activity/',
    listFilterUsersUrl: 'user/list-filter/',

    /* Inventory urls */
    createInventoryUrl: 'inventory/create/',
    manageInventoryUrl: 'inventory/manage/',
    listFilterInventoryUrl: 'inventory/list-filter/',

    /* Requisition urls */
    manageRequisitionUrl: 'requisition/manage/',
    listFilterRequisitionsRequestedUrl: 'requisition/requested-list-filter/',

    /* Reporting urls */
    reportingDirectorDashboardUrl: 'reporting/director-dashboard/',
    reportingCoLoaderOperationUrl: 'reporting/co-loader-operation/',
    reportingCoLoaderManagementUrl: 'reporting/co-loader-management/',
    reportingBookingDataPendingUrl: 'reporting/booking-data-pending/',
    reportingBookingTrackingUrl: 'reporting/booking-tracking/',
    reportingBookingTrackingFileUrl: 'reporting/booking-tracking-file/',
    reportingBookingTrackingSingleUrl: 'reporting/booking-single-tracking/',
    reportingBookingTrackingSingleExternalUrl: 'reporting/booking-single-tracking-external/',
    reportingBookingDrillDownUrl: 'reporting/booking-drill-down/',
    reportingCustomerDeliveryPerformanceUrl: 'reporting/customer-delivery-performance/',
    reportingPodPerformanceUrl: 'reporting/pod-performance/',
    reportingCrmDashboardUrl: 'reporting/crm-dashboard/',
    reportingPendingDelivery: 'reporting/pending-delivery/',

    /* File import urls */
    hubImportUrl: 'file_import/hub-import/',
    branchImportUrl: 'file_import/branch-import/',
    locationImportUrl: 'file_import/location-import/',
    clientImportUrl: 'file_import/client-import/',
    clientBranchImportUrl: 'file_import/client-branch-import/',
    clientBranchLocationImportUrl: 'file_import/client-branch-location-import/',
    coLoaderImportUrl: 'file_import/co-loader-import/',
    bookingImportUrl: 'file_import/booking-import/',
    podManagementImportUrl: 'file_import/pod-management-import/',

    /* Booking urls */
    createBookingUrl: 'booking/create/',
    manageBookingUrl: 'booking/manage/',
    listFilterBookingsCreatedUrl: 'booking/created-list-filter/',
    listBookingsCreatedUrl: 'booking/created-list/',

    /* Manifest urls */
    createManifestUrl: 'manifest_data/manifest-create/',
    manageManifestUrl: 'manifest_data/manifest-manage/',
    listFilterManifestsCreatedUrl: 'manifest_data/created-list-filter/',
    listFilterManifestsManagementUrl: 'manifest_data/management-list/',
    createManifestRecordUrl: 'manifest_data/manifest-record-create/',
    manageManifestRecordUrl: 'manifest_data/manifest-record-manage/',
    listFilterManifestsUpcomingUrl: 'manifest_data/upcoming-list-filter/',
    listFilterManifestsUpcomingHistoricalUrl: 'manifest_data/upcoming-list-filter-historical/',

    /* Manifest issue urls */
    listManifestIssuesUrl: 'manifest_data_issue/list/',

    /* Co-loading urls */
    createColoadingManifestUrl:'co_loader_manifest_data/create/',
    manageColoadingManifestUrl:'co_loader_manifest_data/manage/',
    getManifestsForColoadingUrl: 'manifest_data/list-co-loading/',
    listFilterCoLoadingUrl: 'co_loader_manifest_data/created-list-filter/',
    
    /* Direct delivery urls */
    createDirectDeliveryUrl:'co_loader_manifest_data/create-direct-delivery/',
    
    /* Delivery run sheet urls */
    createDeliveryRunSheetUrl: 'delivery_run_sheet/create/',
    manageDeliveryRunSheetUrl: 'delivery_run_sheet/manage/',
    listFilterDeliveryRunSheetsCreatedUrl: 'delivery_run_sheet/created-list-filter/',
    listFilterDeliveryRunSheetsManagementUrl: 'delivery_run_sheet/management-list-filter/',
    getDeliveryRunSheetUsersUrl: 'delivery_run_sheet/list-users/',
    createDeliveryRunSheetRecordUrl: 'delivery_run_sheet/record-create/',
    manageDeliveryRunSheetRecordUrl: 'delivery_run_sheet/record-manage/',
    
    /* Pod management urls */
    listFilterPodManagementUrl: 'booking/pod-management-list-filter/',
    listFilterPodManagementDownloadUrl: 'booking/pod-management-download/',
};

export const ErrorMessages = {
    /* Generic messages */
    required: 'This field cannot be blank.',
    maxLength: 'This value must be no more than ',
    minLength: 'This value must be no less than ',
    invalidDate: 'Invalid date.',
    invalid: 'Invalid value.',
    fileRequired: 'You must select a file.',

    /* Server messages */
    serverErrorPrefix: 'Issue: ',
    serverBadRequestError: 'Something went wrong with your request. Please try again later.',
    severForbiddenError: 'You are not authorized to make this request.',
    serverNotFoundError: 'This data either does not exist or you are not authorized to view it.',
    serverRequestError: 'Server is not responding. Please check your internet connection or try again later.',
    serverGenericError: 'Something went wrong. Please check your internet connection or try again later.',
    
    /* Reporting messages */
    reportingDateRangeNotSelected: 'Please select Date Range.',
    reportingClientNotSelected: 'Please select Billing Client.',
    reportingClientsNotSelected: 'Please select a single or multiple Billing Clients.',
    reportingBranchNotSelected: 'Please select a Branch.',
    reportingBranchesNotSelected: 'Please select a single or multiple Branches.',
    reportingAwbNumberInputEmpty: 'Please enter a comma-separated list of AWB numbers.',
};

export const SuccessMessages = {
    /* Hub messages */
    hubCreated: 'The hub has been successfully created.',
    hubUpdated: 'The hub has been successfully updated.',
    hubDeleted: 'The hub has been successfully deleted.',

    /* Branch messages */
    branchCreated: 'The branch has been successfully created.',
    branchUpdated: 'The branch has been successfully updated.',
    branchDeleted: 'The branch has been successfully deleted.',

    /* Location messages */
    locationCreated: 'The location has been successfully created.',
    locationUpdated: 'The location has been successfully updated.',
    locationDeleted: 'The location has been successfully deleted.',

    /* Client messages */
    clientCreated: 'The billing client has been successfully created.',
    clientUpdated: 'The billing client has been successfully updated.',
    clientDeleted: 'The billing client has been successfully deleted.',

    /* Client branch messages */
    clientBranchCreated: 'The client branch code has been successfully created.',
    clientBranchUpdated: 'The client branch code has been successfully updated.',
    clientBranchDeleted: 'The client branch code has been successfully deleted.',

    /* Client branch location messages */
    clientBranchLocationCreated: 'The client branch location has been successfully created.',
    clientBranchLocationUpdated: 'The client branch location has been successfully updated.',
    clientBranchLocationDeleted: 'The client branch location has been successfully deleted.',

    /* Co-loader messages */
    coLoaderCreated: 'The co-loader has been successfully created.',
    coLoaderUpdated: 'The co-loader has been successfully updated.',
    coLoaderDeleted: 'The co-loader has been successfully deleted.',
    coLoaderHubsCreated: 'The co-loader hubs have been successfully saved.',
    
    /* Remark messages */
    remarkCreated: 'The remark has been successfully created.',
    remarkUpdated: 'The remark has been successfully updated.',
    remarkDeleted: 'The remark has been successfully deleted.',

    /* User messsages */
    adminCreated: 'The admin has been successfully created.',
    adminUpdated: 'The admin has been successfully updated.',
    userCreated: 'The user has been successfully created.',
    userUpdated: 'The user has been successfully updated.',
    userDeleted: 'The user has been successfully deleted.',

    /* Inventory messages */
    inventoryCreated: 'The stationery item has been successfully created.',
    inventoryUpdated: 'The stationery item has been successfully updated.',
    inventoryDeleted: 'The stationery item has been successfully deleted.',
    
    /* Requisition messages */
    requisitionDeleted: 'The requisition request has been successfully deleted.',
    requisitionFulfilled: 'The requisition request has been successfully fulfilled.',

    /* File import messages */
    fileImportSuccessful: 'The excel file data has been successfully imported.',

    /* Booking messages */
    bookingCreated: 'The booking has been successfully created.',
    bookingUpdated: 'The booking has been successfully updated.',
    bookingDeleted: 'The booking has been successfully deleted.',
    
    /* Manifest messages */
    manifestCreated: 'The regular manifest has been successfully created.',
    manifestUpdated: 'The regular manifest has been successfully updated.',
    manifestDeleted: 'The regular manifest has been successfully deleted.',
    manifestMarkedReceived: 'The regular manifest has been successfully marked received.',
    manifestRecordCreated: 'The regular manifest booking has been successfully created.',
    manifestRecordUpdated: 'The regular manifest booking has been successfully updated.',
    manifestRecordDeleted: 'The regular manifest booking has been successfully deleted.',
    manifestRecordPiecesShortUpdated: 'The regular manifest booking has been successfully marked short.',
    manifestRecordIssueUpdated: 'The regular manifest booking issue has been successfully saved.',
    manifestRecordMarkedReceived: 'The regular manifest booking has been successfully marked received.',
    
    /* Co-loading messages */
    coLoadingManifestCreated: 'The co-loader manifest has been successfully created.',
    coLoadingManifestUpdated: 'The co-loader manifest has been successfully updated.',
    coLoadingManifestDeleted: 'The co-loader manifest has been successfully deleted.',
    coLoadingManifestNoPending: 'There are no pending regular manifests to be co-loaded from your branch.',
    deCoLoadedManifest: 'The regular manifest has been successfully removed from the co-loader manifest.',
    
    /* Direct delivery messages */
    directDeliveryCreated: 'The direct delivery has been successfully created.',
    
    /* Delivery run sheet messages */
    deliveryRunSheetCreated: 'The delivery run sheet has been successfully created.',
    deliveryRunSheetUpdated: 'The delivery run sheet has been successfully updated.',
    deliveryRunSheetDeleted: 'The delivery run sheet has been successfully deleted.',
    deliveryRunSheetRecordCreated: 'The delivery run sheet record has been successfully created.',
    deliveryRunSheetRecordUpdated: 'The delivery run sheet record has been successfully updated.',
    deliveryRunSheetRecordDeleted: 'The delivery run sheet record has been successfully deleted.',
};

export const findArrayIntersection = (arr1, arr2) => {
    const set = new Set(arr1);
    return arr2.filter(item => set.has(item));
};

export const formatDate = (date) => {
    let dateObject = new Date(date);
    let month = (dateObject.getMonth() + 1).toString();
    let day = dateObject.getDate().toString();
    let year = dateObject.getFullYear().toString();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
};

export const convertToReadableDate = (date) => {
    return new Date(date).toDateString();
};

export const convertReadableTime = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
};

export const getNestedStatusDate = (object) => {
    if(object.status.id === 2) {
        // Ready For Dispatch
        if(object.manifest_record !== null) {
            return new Date(object.manifest_record.manifest.manifest_date).toDateString();
        }
        else
        {
            return '';
        }
    }
    else if(object.status.id === 3) {
        // In Transit
        if(object.manifest_record !== null && object.manifest_record.manifest.co_loader_manifest !== null) {
            return new Date(object.manifest_record.manifest.co_loader_manifest.forwarding_date).toDateString();
        }
        else
        {
            return '';
        }
    }
    if(object.status.id === 4) {
        // Out For Delivery
        if(object.delivery_run_sheet_record !== null) {
            return new Date(object.delivery_run_sheet_record.delivery_run_sheet.drs_date).toDateString();
        }
        else
        {
            return '';
        }
    }
};

