export const LayoutTypes = {
    vertical: {
        type: 'vertical',
        dataLayoutAttribute: 'vertical',
        dataLeftbarThemeAttribute: 'default',
        dataLeftbarCompactModeAttribute: 'fixed',
    },
    horizontal: {
        type: 'horizontal',
        dataLayoutAttribute: 'topnav',
        dataLeftbarThemeAttribute: 'default',
        dataLeftbarCompactModeAttribute: 'fixed',
    }
};