import React from 'react';
import ReactDOM from 'react-dom';

/* Redux imports */
import { Provider } from 'react-redux';
import store from './store/index';

/* Import starting component */
import App from './App';

import reportWebVitals from './reportWebVitals';

/* Import Bootstrap styles (required with React Boostrap but not required in this app because they are being included locally) */
// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

/* If you want to start measuring performance in your app, pass a function to log results (for example: reportWebVitals(console.log)) or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
