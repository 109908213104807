import React from 'react';

/* Import routes */
import Routes from './routes/Routes';

/* Import global feedback modal */
import FeedbackModal from './components/FeedbackModal';

/* Import scss files */
import './assets/scss/Saas.scss';

const App = (props) => {
  return (
    <React.Fragment>
      <FeedbackModal></FeedbackModal>
      <Routes></Routes>
    </React.Fragment>
  );
};

export default App;
