import { createSlice } from '@reduxjs/toolkit';

/* Set up initial state */
const initialGlobalFeedbackState = {
    show: false,
    message: null,
    type: null,
};

/* Set up slice */
const globalFeedbackSlice = createSlice({
    name: 'error',
    initialState: initialGlobalFeedbackState,
    reducers: {
        showFeedback(state, action) {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        hideFeedback(state) {
            state.show = false;
            state.message = null;
            state.type = null;
        },
    }
});

export const globalFeedbackActions = globalFeedbackSlice.actions;
export default globalFeedbackSlice;