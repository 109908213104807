import { createSlice } from '@reduxjs/toolkit';

/* Import layout constants */
import { LayoutTypes } from '../../constants/layout';

/* Utility function to change the body attribute */
const changeBodyAttribute = (attribute, value) => {
    if(document.body) {
        document.body.setAttribute(attribute, value);
    }
    return true;
}

/* Set up initial state */
const initialLayoutState = {
    type: LayoutTypes.vertical.type,
};

/* Set up slice */
const layoutSlice = createSlice({
    name: 'layout',
    initialState: initialLayoutState,
    reducers: {
        makeVerticalLayout(state) {
            changeBodyAttribute('data-layout', LayoutTypes.vertical.dataLayoutAttribute);
            changeBodyAttribute('data-leftbar-theme', LayoutTypes.vertical.dataLeftbarThemeAttribute);
            changeBodyAttribute('data-leftbar-compact-mode', LayoutTypes.vertical.dataLeftbarCompactModeAttribute);
            state.type = LayoutTypes.vertical.type;
        },
        makeHorizontalLayout(state) {
            changeBodyAttribute('data-layout', LayoutTypes.horizontal.dataLayoutAttribute);
            changeBodyAttribute('data-leftbar-theme', LayoutTypes.horizontal.dataLeftbarThemeAttribute);
            changeBodyAttribute('data-leftbar-compact-mode', LayoutTypes.horizontal.dataLeftbarCompactModeAttribute);
            state.type = LayoutTypes.horizontal.type;
        }
    }
});

export const layoutActions = layoutSlice.actions;
export default layoutSlice;