import React, { Suspense, useState } from 'react';

import Container from 'react-bootstrap/Container';

/* Import containers */
const Topbar = React.lazy(() => import('../Topbar'));
const Navbar = React.lazy(() => import('./Navbar'));
const Footer = React.lazy(() => import('../Footer'));

const loading = () => <div className="text-center"></div>;

const HorizontalLayout = ({ children }, state) => {
    
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    /* Open the menu when having mobile screen */
    const openMenu = () => {
        setIsMenuOpened((prevState) => {
            setIsMenuOpened(!prevState);
        });

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    return (
        <>
            <div className="wrapper">
                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={loading()}>
                            <Topbar
                                isMenuOpened={isMenuOpened}
                                openLeftMenuCallBack={openMenu}
                                navCssClasses="topnav-navbar"
                                topbarDark={false}
                            />
                        </Suspense>

                        <Suspense fallback={loading()}>
                            <Navbar isMenuOpened={isMenuOpened} />
                        </Suspense>

                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>
        </>
    );
};

export default HorizontalLayout;
