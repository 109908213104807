import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import { GlobalValues } from '../constants/global';

/* Auth routes */
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));

/* External tracking routes */
const ExternalTrackingMultiView = React.lazy(() => import('../pages/externalTracking/ExternalTrackingMultiView'));

/* Page routes */
const Dashboard = React.lazy(() => import('../pages/Dashboard'));

const ListHub = React.lazy(() => import('../pages/hub/ListHub'));
const ManageHub = React.lazy(() => import('../pages/hub/ManageHub'));

const ListBranch = React.lazy(() => import('../pages/branch/ListBranch'));
const ManageBranch = React.lazy(() => import('../pages/branch/ManageBranch'));

const ListLocation = React.lazy(() => import('../pages/location/ListLocation'));
const ManageLocation = React.lazy(() => import('../pages/location/ManageLocation'));

const ListClient = React.lazy(() => import('../pages/client/ListClient'));
const ManageClient = React.lazy(() => import('../pages/client/ManageClient'));

const ListClientBranch = React.lazy(() => import('../pages/clientBranch/ListClientBranch'));
const ManageClientBranch = React.lazy(() => import('../pages/clientBranch/ManageClientBranch'));

const ListClientBranchLocation = React.lazy(() => import('../pages/clientBranchLocation/ListClientBranchLocation'));
const ManageClientBranchLocation = React.lazy(() => import('../pages/clientBranchLocation/ManageClientBranchLocation'));

const ListCoLoader = React.lazy(() => import('../pages/coLoader/ListCoLoader'));
const ManageCoLoader = React.lazy(() => import('../pages/coLoader/ManageCoLoader'));

const ListRemark = React.lazy(() => import('../pages/remark/ListRemark'));
const ManageRemark = React.lazy(() => import('../pages/remark/ManageRemark'));

const ListUser = React.lazy(() => import('../pages/user/ListUser'));
const ManageAdmin = React.lazy(() => import('../pages/user/ManageAdmin'));
const ManageUser = React.lazy(() => import('../pages/user/ManageUser'));

const ListInventory = React.lazy(() => import('../pages/inventory/ListInventory'));
const ManageInventory = React.lazy(() => import('../pages/inventory/ManageInventory'));

const ListRequisition = React.lazy(() => import('../pages/requisition/ListRequisition'));
const ManageRequisition = React.lazy(() => import('../pages/requisition/ManageRequisition'));

const DirectorDashboard = React.lazy(() => import('../pages/reporting/DirectorDashboard'));
const CustomerDeliveryPerformance = React.lazy(() => import('../pages/reporting/CustomerDeliveryPerformance'));
const PodPerformance = React.lazy(() => import('../pages/reporting/PodPerformance'));
const CrmDashboard = React.lazy(() => import('../pages/reporting/CrmDashboard'));
const CoLoaderOperation = React.lazy(() => import('../pages/reporting/CoLoaderOperation'));
const CoLoaderManagement = React.lazy(() => import('../pages/reporting/CoLoaderManagement'));
const BookingDataPending = React.lazy(() => import('../pages/reporting/BookingDataPending'));
const BookingTracking = React.lazy(() => import('../pages/reporting/BookingTracking'));
const PendingDelivery = React.lazy(() => import('../pages/reporting/PendingDelivery'));
const RunSheetPendingDelivery = React.lazy(() => import('../pages/reporting/RunSheetPendingDelivery'));

const SuperAdminFileImport = React.lazy(() => import('../pages/fileImport/SuperAdminFileImport'));
const BookingImport = React.lazy(() => import('../pages/fileImport/headquarterBranchUser/BookingImport'));

const ListBooking = React.lazy(() => import('../pages/booking/ListBooking'));
const ListBookingTwo = React.lazy(() => import('../pages/booking/ListBookingTwo'));
const ManageBooking = React.lazy(() => import('../pages/booking/ManageBooking'));
const ManageBookingStatus = React.lazy(() => import('../pages/booking/ManageBookingStatus'));
const ManagePodImage = React.lazy(() => import('../pages/booking/ManagePodImage'));
const ManageDrsImage = React.lazy(() => import('../pages/booking/ManageDrsImage'));

const ListManifest = React.lazy(() => import('../pages/manifest/manageManifest/ListManifest'));
const ManageManifest = React.lazy(() => import('../pages/manifest/manageManifest/ManageManifest'));
const ListManifestManagement = React.lazy(() => import('../pages/manifest/manageManifest/ListManifestManagement'));

const ListUpcomingManifest = React.lazy(() => import('../pages/manifest/manageManifest/ListUpcomingManifest'));
const ManageUpcomingManifest = React.lazy(() => import('../pages/manifest/manageManifest/ManageUpcomingManifest'));

const ListUpcomingManifestHistorical = React.lazy(() => import('../pages/manifest/manageManifest/ListUpcomingManifestHistorical'));

const ListCoLoading = React.lazy(() => import('../pages/coLoading/ListCoLoading'));
const ManageCoLoading = React.lazy(() => import('../pages/coLoading/ManageCoLoading'));

const ManageDirectDelivery = React.lazy(() => import('../pages/directDelivery/ManageDirectDelivery'));
const ListManifestDirectDelivery = React.lazy(() => import('../pages/directDelivery/ListManifestDirectDelivery'));
const ListManifestDirectDeliveryManagement = React.lazy(() => import('../pages/directDelivery/ListManifestDirectDeliveryManagement'));
const ListCoLoadingDirectDelivery = React.lazy(() => import('../pages/directDelivery/ListCoLoadingDirectDelivery'));

const ListDeliveryRunSheet = React.lazy(() => import('../pages/deliveryRunSheet/manageDeliveryRunSheet/ListDeliveryRunSheet'));
const ListDeliveryRunSheetManagement = React.lazy(() => import('../pages/deliveryRunSheet/manageDeliveryRunSheet/ListDeliveryRunSheetManagement'));
const ManageDeliveryRunSheet = React.lazy(() => import('../pages/deliveryRunSheet/manageDeliveryRunSheet/ManageDeliveryRunSheet'));

const PodManagementList = React.lazy(() => import('../pages/podManagement/PodManagementList'));
const PodManagementDownload = React.lazy(() => import('../pages/podManagement/PodManagementDownload'));
const PodManagementImport = React.lazy(() => import('../pages/fileImport/headquarterBranchUser/PodManagementImport'));

const VolumetricCalculator = React.lazy(() => import('../pages/volumetricCalculator/VolumetricCalculator'));

const ManifestPdf = React.lazy(() => import('../pages/pdfExport/ManifestPdf'));
const CoLoaderManifestPdf = React.lazy(() => import('../pages/pdfExport/CoLoaderManifestPdf'));
const UpcomingManifestPdf = React.lazy(() => import('../pages/pdfExport/UpcomingManifestPdf'));
const DeliveryRunSheetPdf = React.lazy(() => import('../pages/pdfExport/DeliveryRunSheetPdf'));
const BookingPdf = React.lazy(() => import('../pages/pdfExport/BookingPdf'));

/* Page Not Found (not being used currently) */
//const PageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));

/* root route */
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

/* Dashboard route */
const dashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    component: Dashboard,
};

/* Authenticated page routes */
const hubAppRoutes = {
    path: '/hub',
    name: 'Hub',
    route: PrivateRoute,
    icon: 'uil-building',
    children: [
        {
            path: '/hub/list',
            name: 'List',
            component: ListHub,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/hub/manage',
            name: 'Manage',
            component: ManageHub,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const branchAppRoutes = {
    path: '/branch',
    name: 'Branch',
    route: PrivateRoute,
    icon: 'uil-map-pin-alt',
    children: [
        {
            path: '/branch/list',
            name: 'List',
            component: ListBranch,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/branch/manage',
            name: 'Manage',
            component: ManageBranch,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const locationAppRoutes = {
    path: '/location',
    name: 'Location',
    route: PrivateRoute,
    icon: 'uil-location-point',
    children: [
        {
            path: '/location/list',
            name: 'List',
            component: ListLocation,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/location/manage',
            name: 'Manage',
            component: ManageLocation,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const clientAppRoutes = {
    path: '/client',
    name: 'Billing Client',
    route: PrivateRoute,
    icon: 'uil-dollar-alt',
    children: [
        {
            path: '/client/list',
            name: 'List',
            component: ListClient,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/client/manage',
            name: 'Manage',
            component: ManageClient,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const clientBranchAppRoutes = {
    path: '/clientbranch',
    name: 'Client Branch Code',
    route: PrivateRoute,
    icon: 'uil-map-pin-alt',
    children: [
        {
            path: '/clientbranch/list',
            name: 'List',
            component: ListClientBranch,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/clientbranch/manage',
            name: 'Manage',
            component: ManageClientBranch,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const clientBranchLocationAppRoutes = {
    path: '/clientbranchlocation',
    name: 'Client Location',
    route: PrivateRoute,
    icon: 'uil-location-point',
    children: [
        {
            path: '/clientbranchlocation/list',
            name: 'List',
            component: ListClientBranchLocation,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/clientbranchlocation/manage',
            name: 'Manage',
            component: ManageClientBranchLocation,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const coLoaderAppRoutes = {
    path: '/coloader',
    name: 'Co-Loader',
    route: PrivateRoute,
    icon: 'uil-truck',
    children: [
        {
            path: '/coloader/list',
            name: 'List',
            component: ListCoLoader,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/coloader/manage',
            name: 'Manage',
            component: ManageCoLoader,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const remarkAppRoutes = {
    path: '/remark',
    name: 'Remark',
    route: PrivateRoute,
    icon: 'uil-comment-dots',
    children: [
        {
            path: '/remark/list',
            name: 'List',
            component: ListRemark,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/remark/manage',
            name: 'Manage',
            component: ManageRemark,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const userAppRoutes = {
    path: '/user',
    name: 'User',
    route: PrivateRoute,
    icon: 'uil-user-plus',
    children: [
        {
            path: '/user/list/',
            name: 'List',
            component: ListUser,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/user/admin/manage',
            name: 'Super Admin Manage',
            component: ManageAdmin,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/user/user/manage',
            name: 'User Manage',
            component: ManageUser,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const inventoryAppRoutes = {
    path: '/inventory',
    name: 'Inventory',
    route: PrivateRoute,
    icon: 'uil-archive',
    children: [
        {
            path: '/inventory/list',
            name: 'List',
            component: ListInventory,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/inventory/manage',
            name: 'Manage',
            component: ManageInventory,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const requisitionAppRoutes = {
    path: '/requisition',
    name: 'Requisition',
    route: PrivateRoute,
    icon: 'uil-archive',
    children: [
        {
            path: '/requisition/list',
            name: 'List',
            component: ListRequisition,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/requisition/manage',
            name: 'Manage',
            component: ManageRequisition,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
    ],
};

const reportingAppRoutes = {
    path: '/reporting',
    name: 'Reporting',
    route: PrivateRoute,
    icon: 'uil-chart-bar',
    children: [
        {
            path: '/reporting/director_dashboard',
            name: 'Director\'s Dashboard',
            component: DirectorDashboard,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/reporting/customer_delivery_performance',
            name: 'Customer Delivery Performance',
            component: CustomerDeliveryPerformance,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
        {
            path: '/reporting/pod_performance',
            name: 'POD Performance',
            component: PodPerformance,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
        {
            path: '/reporting/crm_dashboard',
            name: 'CRM Dashboard',
            component: CrmDashboard,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
        {
            path: '/reporting/co_loader_operation',
            name: 'Co-Loader Operations',
            component: CoLoaderOperation,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/reporting/co_loader_management',
            name: 'Co-Loader Management',
            component: CoLoaderManagement,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
        {
            path: '/reporting/booking_data_pending',
            name: 'Booking Data Pending',
            component: BookingDataPending,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/reporting/booking_tracking',
            name: 'Tracking',
            component: BookingTracking,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/reporting/pending_delivery',
            name: 'All Pending Deliveries',
            component: PendingDelivery,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/reporting/drs_pending_delivery',
            name: 'DRS Pending Deliveries',
            component: RunSheetPendingDelivery,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/reporting/received_manifests',
            name: 'Received Manifests',
            component: ListUpcomingManifestHistorical,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const fileImportAppRoutes = {
    path: '/file_import',
    name: 'File Import',
    route: PrivateRoute,
    icon: 'uil-export',
    children: [
        {
            path: '/file_import/sa_upload',
            name: 'Import',
            component: SuperAdminFileImport,
            route: PrivateRoute,
            roles: [GlobalValues.superAdminRoleId],
        },
        {
            path: '/file_import/booking',
            name: 'Booking Data',
            component: BookingImport,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const bookingAppRoutes = {
    path: '/booking',
    name: 'Booking',
    route: PrivateRoute,
    icon: 'uil-tag-alt',
    children: [
        {
            path: '/booking/list',
            name: 'List Billing Data',
            component: ListBooking,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/booking/list_mis',
            name: 'List MIS Data',
            component: ListBookingTwo,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/booking/manage',
            name: 'Manage',
            component: ManageBooking,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/booking/detail',
            name: 'Detail',
            component: ManageBookingStatus,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/booking/pod_upload',
            name: 'Upload POD Image',
            component: ManagePodImage,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/booking/drs_upload',
            name: 'Upload DRS Image',
            component: ManageDrsImage,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const manifestAppRoutes = {
    path: '/manifest',
    name: 'Regular Manifest',
    route: PrivateRoute,
    icon: 'uil-folder-medical',
    children: [
        {
            path: '/manifest/list',
            name: 'List',
            component: ListManifest,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/manifest/management',
            name: 'Management List',
            component: ListManifestManagement,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/manifest/manage',
            name: 'Manage',
            component: ManageManifest,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const upcomingAppRoutes = {
    path: '/upcoming',
    name: 'Upcoming Manifest',
    route: PrivateRoute,
    icon: 'uil-clipboard-notes',
    children: [
        {
            path: '/upcoming/list',
            name: 'List',
            component: ListUpcomingManifest,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/upcoming/manage',
            name: 'Manage',
            component: ManageUpcomingManifest,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const coLoadingAppRoutes = {
    path: '/coloading',
    name: 'Co-Loading',
    route: PrivateRoute,
    icon: 'uil-truck',
    children: [
        {
            path: '/coloading/list',
            name: 'List',
            component: ListCoLoading,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/coloading/manage',
            name: 'Manage',
            component: ManageCoLoading,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const directDeliveryRoute = {
    path: '/directdelivery',
    name: 'Direct Delivery',
    route: PrivateRoute,
    icon: 'uil-subway',
    children: [
        {
            path: '/directdelivery/manifestlist',
            name: 'List Manifest Wise',
            component: ListManifestDirectDelivery,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/directdelivery/manifestmanagement',
            name: 'List Management Wise',
            component: ListManifestDirectDeliveryManagement,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/directdelivery/coloadinglist',
            name: 'List CoLoading Wise',
            component: ListCoLoadingDirectDelivery,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/directdelivery/create',
            name: 'Create',
            component: ManageDirectDelivery,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const deliveryRunSheetAppRoutes = {
    path: '/deliveryrunsheet',
    name: 'Delivery Run Sheet',
    route: PrivateRoute,
    icon: 'uil-file-upload-alt',
    children: [
        {
            path: '/deliveryrunsheet/list',
            name: 'List',
            component: ListDeliveryRunSheet,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/deliveryrunsheet/management',
            name: 'Management List',
            component: ListDeliveryRunSheetManagement,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/deliveryrunsheet/manage',
            name: 'Manage',
            component: ManageDeliveryRunSheet,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const podManagementAppRoutes = {
    path: '/podmanagement',
    name: 'POD Management',
    route: PrivateRoute,
    icon: 'uil-camera-change',
    children: [
        {
            path: '/podmanagement/list',
            name: 'POD Management List',
            component: PodManagementList,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
        {
            path: '/podmanagement/download',
            name: 'POD Management Download',
            component: PodManagementDownload,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
        {
            path: '/podmanagement/upload',
            name: 'POD Management Upload',
            component: PodManagementImport,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId],
        },
    ],
};

const calculatorAppRoutes = {
    path: '/calculator',
    name: 'Calculator',
    route: PrivateRoute,
    icon: 'uil-calculator-alt',
    children: [
        {
            path: '/calculator/volumetric',
            name: 'Volumetric Weight',
            component: VolumetricCalculator,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const fileExportAppRoutes = {
    path: '/file_export',
    name: 'File Export',
    route: PrivateRoute,
    icon: 'uil-file-share-alt',
    children: [
        {
            path: '/file_export/manifest',
            name: 'Export Manifest',
            component: ManifestPdf,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/file_export/co_loader_manifest',
            name: 'Export Co-Loader Manifest',
            component: CoLoaderManifestPdf,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/file_export/upcoming_manifest',
            name: 'Export Upcoming Manifest',
            component: UpcomingManifestPdf,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/file_export/delivery_run_sheet',
            name: 'Export Delivery Run Sheet',
            component: DeliveryRunSheetPdf,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
        {
            path: '/file_export/booking',
            name: 'Export Booking',
            component: BookingPdf,
            route: PrivateRoute,
            roles: [GlobalValues.headquarterBranchUserRoleId, GlobalValues.branchUserRoleId],
        },
    ],
};

const appRoutes = [
    hubAppRoutes,
    branchAppRoutes,
    locationAppRoutes,
    clientAppRoutes,
    clientBranchAppRoutes,
    clientBranchLocationAppRoutes,
    coLoaderAppRoutes,
    remarkAppRoutes,
    userAppRoutes,
    inventoryAppRoutes,
    requisitionAppRoutes,
    reportingAppRoutes,
    fileImportAppRoutes,
    bookingAppRoutes,
    manifestAppRoutes,
    upcomingAppRoutes,
    coLoadingAppRoutes,
    directDeliveryRoute,
    deliveryRunSheetAppRoutes,
    podManagementAppRoutes,
    calculatorAppRoutes,
    fileExportAppRoutes,
];

/* Flatten the list of all nested routes */
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

/* Auth routes */
const authRoutes = [
    {
        path: '/account/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];

/* External tracking routes */
const externalTrackingRoutes = [
    {
        path: '/track',
        name: 'External Booking Tracking',
        component: ExternalTrackingMultiView,
        route: Route,
    },
];

/* All routes */
const authProtectedRoutes = [rootRoute, dashboardRoute, ...appRoutes];
const publicRoutes = [...authRoutes, ...externalTrackingRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
